import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DsaAssociation } from 'src/app/model/dsa-association';
import { DsaAssociationService } from '../../service/dsa-association.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/service/language.service';

@Component({
  selector: 'app-center-selection-popup',
  templateUrl: './center-selection-popup.component.html',
  styleUrls: ['./center-selection-popup.component.css']
})
export class CenterSelectionPopupComponent implements OnInit {

  @Input() dsaUserId: string;
  @Output() proceed = new EventEmitter<any>();

  form: UntypedFormGroup;
  associationList: DsaAssociation[] = [];

  constructor(
    public modal: NgbActiveModal,
    private _toastrService: ToastrService,
    private _languageService: LanguageService,
    private _dsaAssociationService: DsaAssociationService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.form = this._formBuilder.group({
      centerGuid: [null, Validators.required]
    });
  }

  ngOnInit() {
    if (this.dsaUserId) {
      this._dsaAssociationService
        .getActiveDsaAssociationsByUser(this.dsaUserId)
        .subscribe((list: DsaAssociation[]) =>
          this.associationList = list,
          err => console.log(err),
          async () => {
            if (this.associationList.length > 0) {
              this.form.controls.centerGuid
                .setValue(this.associationList[0].clinicGuid);
            } else {
              this.modal.close();
              const toastError = await this._languageService
                .getTranslation('authenticate.noActiveCenters');
              this._toastrService.error(toastError);
            }
          });
    }
  }

  async proceedWithCenter() {
    if (this.form.invalid || !this.form.value.centerGuid) {
      const toastError = await this._languageService
        .getTranslation('authenticate.pleaseSelectACenter');
      this._toastrService.error(toastError);
    } else {
      this.proceed.emit(this.form.value as { centerGuid: string });
      this.modal.close();
    }
  }

}
