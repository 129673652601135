import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DsaAssociationService {

  private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION + 'dsaassociations';

  constructor(private http: HttpClient) { }

  getActiveDsaAssociationsByUser(id: string) {
    return this.http.get(`${this.url}/getbydsauserid/${id}`);
  }
}
