import { UntypedFormGroup } from "@angular/forms";

export class CommonFunctions {

    static showInvalidFormControls(formGroup: UntypedFormGroup): void {
        if (formGroup !== undefined) {
            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);
                control.markAsDirty({ onlySelf: true });
                control.markAsTouched({ onlySelf: true });
            });
        }
    }

    static showError(error: string): void {
        console.log('Error:: dentbox: ' + error);
    }

    static checkPasswords(group: UntypedFormGroup) {
        const password = group.get('password').value;
        const confirmPassword = group.get('confirmPassword').value;
        return password === confirmPassword ? null : { notSame: true };
    }
}