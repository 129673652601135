import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConnection } from './apiConnection';
import { Lab } from '../model/lab';


@Injectable()
export class LabService {

    private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION + 'labs';
    selectedUser: Lab;

    constructor(private http: HttpClient) { }

    public getLabs(): any {
        return this.http.get(`${this.url}`);
    }

    public getLabById(id: string): any {
        return this.http.get(`${this.url}/${id}`);
    }

    public getClinicByGuid(guid: string): any {
        return this.http.get(`${this.url}/getbyguid/${guid}`);
    }

    putLab(lab: Lab): any {
        return this.http.put(`${this.url}/${lab._id}`, lab);
    }

}

