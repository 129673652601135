import { SafeResourceUrl } from "@angular/platform-browser";

export class Attachment {
    originalName: string;
    newName: string;
    url: string;
    size: number;
    extension: string;
    type: string;
    safeUrl: SafeResourceUrl;
    thumbnailName: string;
    thumbnailUrl: string;

    constructor(_originalName: string, _newName: string, _url: string, _type: string, _extension: string, _size?: number, _safeUrl?: SafeResourceUrl, _thumbnailName?: string, _thumbnailUrl?: string) {
        this.originalName = _originalName;
        this.newName = _newName;
        this.url = _url;
        this.extension = _extension;
        this.type = _type;
        this.size = _size;
        this.safeUrl = _safeUrl;
        this.thumbnailName = _thumbnailName;
        this.thumbnailUrl = _thumbnailUrl;
    }
}