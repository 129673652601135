import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { USERS } from 'src/app/common/endpoints';
import { RecoverPasswordViewModel } from 'src/app/model/recover-password-view-model';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/service/language.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotFormGroup: UntypedFormGroup;
  forgotForm: NgForm;
  rpvm: RecoverPasswordViewModel;
  
  constructor(private _fb: UntypedFormBuilder,
    private _uiLoaderService: NgxUiLoaderService,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _languageService: LanguageService) {
      this.rpvm = new RecoverPasswordViewModel();
    }

  ngOnInit() {
    this.forgotFormGroup = this._fb.group({
      email: ['', [Validators.required]]
    });
  }

  async recoverPassword(form: NgForm, event) {
    if (this.rpvm.email) {
      try {
        this._uiLoaderService.start();
        let emailObj = await this.getEmailTranslations(this.rpvm.email);
        
        event.target.disabled = true;
        const response = await this._authService.post(USERS.FORGOT, emailObj).toPromise();
        if (response && response.body) {
          if (response.body.status === 200) {
            const toastMsge = await this._languageService.getTranslation('authenticate.password_reset_link_sent');
            this._toastrService.success(toastMsge);
          }
          else if (response.body.status === 400) {
            const toastMsge = await this._languageService.getTranslation('authenticate.invalid_email_sent');
            this._toastrService.error(toastMsge);
          }
          else {
            let message = response.body.data;
            let errorMsge = "";
  
            if (message === "AuthenticationException") {
              let errorMsge1 = await this._languageService.getTranslation('authenticate.authenticationException1');
              let errorMsge2 = await this._languageService.getTranslation('authenticate.authenticationException2');
              let errorMsge3 = await this._languageService.getTranslation('authenticate.authenticationException3');
              errorMsge = errorMsge1 + ' </br> ' + errorMsge2 + ' </br> ' + errorMsge3;
            }
            
            if (message === "Exception") {
              errorMsge = await this._languageService.getTranslation('authenticate.smtpException');
            }
            const toastErrorMsge = await this._languageService.getTranslation('authenticate.email_not_sent');
            const toastMsge = toastErrorMsge + ' </br> ' + errorMsge;
            this._toastrService.error(toastMsge, '', { enableHtml: true });
          }
        }
        else {
          const toastMsge = await this._languageService.getTranslation('authenticate.email_sending_failed');
          this._toastrService.error(toastMsge);
        }
      } catch (error) {
        console.log(error);
        const toastMsge = await this._languageService.getTranslation('authenticate.email_sending_failed');
        this._toastrService.error(toastMsge);
      } finally {
        this.forgotFormGroup.reset();
        event.target.disabled = false;
        this._uiLoaderService.stop();
      }
    }
  }

  async getEmailTranslations(email: string) {
    let emailObj = { emailAddress: email, emailHeader: "", emailMessage1: "", emailMessage2: "" }

    emailObj.emailHeader = await this._languageService.getTranslation('authenticate.reset_password');
    emailObj.emailMessage1 = await this._languageService.getTranslation('authenticate.emailMessage1'); 
    emailObj.emailMessage2 = await this._languageService.getTranslation('authenticate.emailMessage2');

    return emailObj;
  }

}
