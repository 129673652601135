import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { Message } from '../model/message';
import { Observable } from 'rxjs';
import { Work } from '../model/work';
import * as io from 'socket.io-client';

@Injectable()
export class SocketService {

  /** Configuring a global socket */

  private socket;

  constructor() {
    let connected = false;
    const RETRY_INTERVAL = 10000;

    this.socket = io(ApiConnection.BASE_URL, {
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      secure: true,
      reconnection: true,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity
    });

    this.socket.on('connect', function () {
      connected = true;
    });
    this.socket.on('disconnect', function () {
      connected = false;
      retryConnectOnFailure(RETRY_INTERVAL);
    });

    const retryConnectOnFailure = function (retryInMilliseconds) {
      setTimeout(function () {
        if (!connected) {

          // http.get(`${ApiConnection.BASE_URL + ApiConnection.API_VERSION}ping`).subscribe(
          //     (data: any) => {
          //         connected = true;
          //         window.location.href = unescape(window.location.pathname);
          //     },
          //     (error: any) => {
          //         retryConnectOnFailure(retryInMilliseconds);
          //     }
          // );
        }
      }, retryInMilliseconds);
    };

    this.socket.on('connect_error', (error) => {
      // console.log(error);
    });

    this.socket.on('connect_timeout', (timeout) => {
      // console.log(timeout);
    });

    this.socket.on('error', (error) => {
      //  console.log(error);
    });

    this.socket.on('reconnect_attempt', () => {
      this.socket.io.opts.transports = ['polling', 'websocket'];
    });

    /** eof sockets */
  }

  // Messages CRUD with Socket IO
  public async sendMessageEmitter(message: Message): Promise<void> {
    this.socket.emit('new-message', message);
  }

  public sendMessageReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('new-message', (message) => {
        observer.next(message);
      });
    });
  }

  public async updateMessageEmitter(message: Message): Promise<void> {
    this.socket.emit('update-message', message);
  }

  public updateMessageReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('update-message', (message) => {
        observer.next(message);
      });
    });
  }

  public deleteMessageEmitter = (messageId: string) => {
    this.socket.emit('delete-message', messageId);
  }

  public deleteMessageReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('delete-message', (message) => {
        observer.next(message);
      });
    });
  }

  public getMessagesEmitter = () => {
    this.socket.emit('get-messages');
  }

  public getMessagesReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('get-messages', (messages) => {
        observer.next(messages);
      });
    });
  }

  public getUnseenMessagesEmitter = () => {
    this.socket.emit('unseen-message-list');
  }

  public getUnseenMessagesReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('unseen-message-list', (messages) => {
        observer.next(messages);
      });
    });
  }

  public getAttachmentsByWorkIdEmitter = (workId: string) => {
    this.socket.emit('get-attachment-list', workId);
  }

  public getAttachmentsByWorkIdReciever() {
    return Observable.create((observer) => {
      this.socket.on('get-attachment-list', (attachments) => {
        observer.next(attachments);
      });
    });
  }

  // Works CRUD with Socket IO

  public getWorksEmitter = (id: string, type: string) => {
    this.socket.emit('get-works', id, type);
  }

  public getWorksReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('get-works', (works) => {
        observer.next(works);
      });
    });
  }

  public async updateWorkEmitter(work: Work): Promise<void> {
    this.socket.emit('update-work', work);
  }

  public updateWorkReciever = () => {
    return Observable.create((observer) => {
      this.socket.on('update-work', (work) => {
        observer.next(work);
      });
    });
  }

  public syncMessageStatusEmitter = (workId?: string) => {
    this.socket.emit('sync-message-status', workId);
  }

  public syncMessageStatusReceiver = () => {
    return Observable.create((observer) => {
      this.socket.on('sync-message-status', (workId) => {
        observer.next(workId);
      });
    });
  }

}

