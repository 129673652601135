import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../service/authorization.service';
import { ApplicationUser } from 'src/app/model/applicationUser';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/service/clinic.service';
import { LabService } from '../../service/lab.service';
import { WorkService } from '../../service/work.service';
import { ChatService } from '../../service/chat.service';
import { SocketService } from '../../service/sockets.service';
import { Work } from '../../model/work';

@Component({
  selector: 'work-chat',
  templateUrl: './work-chat.component.html',
  styleUrls: ['./work-chat.component.css']
})
export class WorkChatComponent implements OnInit {

  private model: ApplicationUser;
  private user: any;
  private workId: string;
  private _count = 0;

  public providedWork: Work;

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthorizationService,
    private _toaster: ToastrService,
    private _clinicService: ClinicService,
    private _labService: LabService,
    private _workService: WorkService,
    private _chatService: ChatService,
    private _socketService: SocketService) { }

  async ngOnInit() {
    if (sessionStorage.getItem('5EC358E33FC855F0268D055B457B1D68')) {
      const workGuid = sessionStorage.getItem('5EC358E33FC855F0268D055B457B1D68');
      const work: Work = await this._workService.getWorkByGuid(workGuid).toPromise();
      if (work) {
        if (work._id) {
          this.providedWork = work;
          this._chatService.setSelectedWork(this.providedWork);
          this._chatService.setAndPublishMessage(this.providedWork, undefined, undefined);
          this._socketService.getAttachmentsByWorkIdEmitter(this.providedWork['_id']);
          this._chatService.setIsHome();
          this._chatService.setLatest(0);
          this._chatService.setOldest(0);
        } else {
          if (this._count === 0) { this._count++; location.reload(); } else { this._toaster.error('Invalid work Id!', 'INVALID WORK ID!'); }
        }
      }
    }
  }
}
