import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    translations: any[];

    constructor(private _translationService: TranslateService) {
        this._translationService.onLangChange.subscribe(
            (trans: { lang: string, translations: any }) => {
                this.translations = trans.translations;
            });
    }

    setLanguageSettings() {
        this._translationService.addLangs(['en', 'es']);
        this._translationService.setDefaultLang('en');
        if (sessionStorage.lang) {
            this._translationService.use(sessionStorage.lang);
        } else {
            const browserLang = this._translationService.getBrowserLang();
            this._translationService.use(browserLang.match(/en|es/) ? browserLang : 'en');
        }
    }

    async getTranslation(key: string) {
        return this._translationService.get(key)
            .pipe(map(trans => trans as string)).toPromise();
    }
}