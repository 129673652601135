import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Lab } from 'src/app/model/lab';
import { Work } from 'src/app/model/work';
import { ChatService } from 'src/app/service/chat.service';
import * as moment from 'moment';
import { ShowInvalidFormControls } from 'src/app/service/validators';
import { ToastrService } from 'ngx-toastr';
import { CommonUiService } from 'src/app/service/common.ui.service';
import { LanguageService } from 'src/app/service/language.service';
import { User } from 'src/app/model/user';
import { SocketService } from 'src/app/service/sockets.service';
import { WorkService } from 'src/app/service/work.service';

@Component({
  selector: 'app-add-work',
  templateUrl: './add-work.component.html',
  styleUrls: ['./add-work.component.css']
})
export class AddWorkComponent implements OnInit {
  public labList: any[] = [];
  public work: Work;
  isEdit = false;
  loggedUser: any;
  previousDoctorName = "";

  // formgroups
  workFormGroup: UntypedFormGroup;

  constructor(public modal: NgbActiveModal, private _chatService: ChatService, private formBuilder: UntypedFormBuilder, private _uiLoaderService: NgxUiLoaderService, private _toastrService: ToastrService, public commonUiService: CommonUiService, private _languageService: LanguageService, private _workService: WorkService) {
    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    if (data) {
      this.loggedUser = JSON.parse(data);
    }
  }

  ngOnInit() {
    this.getLabsByCenterGuid(this.loggedUser.guid);
    this.InitFormGroups();
    if (this.isEdit) {
      this.InitializeEditFormForGroup(this.work);
    }
  }

  private InitFormGroups() {
    const guid = Guid.create();
    this.workFormGroup = this.formBuilder.group({
      clinicId: [],
      labId: ['', [Validators.required]],
      labName: ['', [Validators.required]],
      description: [''],
      patientName: ['', [Validators.required]],
      guid: [this.isEdit ? '' : guid],
      collaborator: [''],
      entryDate: [null],
      deliveryDate: [null],
    });
    this.workFormGroup.controls.entryDate.setValue(this.GetCurrentDate());
  }

  private InitializeEditFormForGroup(work: Work) {
    this.workFormGroup.setValue({
      clinicId: work.clinicId ? work.clinicId : '',
      labId: work.labId ? work.labId : '',
      labName: work.labName ? work.labName : '',
      description: work.description ? work.description : '',
      patientName: work.patientName ? work.patientName : '',
      guid: work.guid ? work.guid : '',
      collaborator: work.collaboratorDescription ? work.collaboratorDescription : '',
      entryDate: work.entryDate ? this.commonUiService.ToNgbDate(new Date(work.entryDate)) : null,
      deliveryDate: work.deliveryDate ? this.commonUiService.ToNgbDate(new Date(work.deliveryDate)) : null,
    });
    this.workFormGroup.controls.guid.disable();
    this.workFormGroup.controls.entryDate.disable();

    const userType = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    if (userType === 'lab') {
      this.workFormGroup.controls.labName.disable();
    } else {
      this.workFormGroup.controls.labName.enable();
    }
  }

  GetCurrentDate() {
    return this.commonUiService.ConvertToPickerDateFormat(moment().format('YYYY-MM-DD'));
  }

  // get all connected active labs
  private getLabsByCenterGuid(centerGuid: string) {
    this._chatService.getLabsByCenterGuid(centerGuid).subscribe(
      res => {
        this.labList = res.data;
      },
      err => console.error(err),
      () => {
      });
  }

  async onSelectLab(lab?: any) {
    try {
      if (lab) {
        this.workFormGroup.controls.labId.setValue(lab.centerId);
      } else {
        this.workFormGroup.controls.labId.setValue(null);
      }
    } catch (error) { console.log(error); } finally {
    }
  }

  async saveWork() {
    if (this.workFormGroup.invalid) {
      ShowInvalidFormControls(this.workFormGroup); return;
    } else {
      this._uiLoaderService.start();
      const work = this.workFormGroup.value;
      let workData = new Work();
      workData = work;
      if (workData.entryDate) {
        workData.entryDate = this.commonUiService.ChangeNgbDateToCustomFormat(workData.entryDate).toString();
      }
      if (workData.deliveryDate) {
        workData.deliveryDate = this.commonUiService.ChangeNgbDateToCustomFormat(workData.deliveryDate).toString();
      }
      if (workData.collaborator) {
        localStorage.setItem('doctor-name', workData.collaborator); // save latest previous doctor name in local storage
      }
      if (this.work._id && this.work._id.length > 0) {
        this._uiLoaderService.start();
        workData.entryDate = this.work.entryDate;
        workData._id = this.work._id;
        workData.state = this.work.state;
        workData.clinicId = this.work.clinicId;
        workData.guid = this.work.guid;
        this._chatService.updateWork(workData)
          .subscribe(
            async res => {
              if (res) {
                res.labName = workData.labName;
                this._workService.workSubject.next(res);
                this._chatService.setSelectedWork(res);
                this._workService.workViewSubject.next(res);
                const successMsge = await this._languageService.getTranslation('common.update_success');
                this._toastrService.success(successMsge);
                this._uiLoaderService.stop();
                this.modal.close();
              }
            },
            async err => {
              console.log(err);
              const errorMsge = await this._languageService.getTranslation('common.update_error');
              this._toastrService.error(errorMsge);
              this._uiLoaderService.stop();
              this.modal.close();
            });
      } else {
        this._uiLoaderService.start();
        workData.state = 'pending';
        workData.guid = work.guid ? work.guid.value : '';
        workData.clinicId = this.loggedUser._id;
        const language = sessionStorage.getItem('currentLang');
        this._chatService.saveWork(language, workData)
          .subscribe(
            async res => {
              if (res) {
                res.labName = workData.labName;
                this._workService.workSubject.next(res);
                this._chatService.setSelectedWork(res);
                this._workService.workViewSubject.next(res);
                const successMsge = await this._languageService.getTranslation('common.save_success');
                this._toastrService.success(successMsge);
                this._uiLoaderService.stop();
                this.modal.close();
                //this._workService.workSubject.next(null);
              }
            },
            async err => {
              console.log(err);
              const errorMsge = await this._languageService.getTranslation('common.save_error');
              this._toastrService.error(errorMsge);
              this._uiLoaderService.stop();
              this.modal.close();
            });
      }
    }
  }

  onCloseModal() {
    this.modal.close();
    this._uiLoaderService.stop();
  }

  onFocus(focusIn: boolean) {
    this.previousDoctorName = focusIn ? localStorage.getItem('doctor-name') : '';
  }

  onSelectDoctor() {
    this.workFormGroup.controls.collaborator.setValue(this.previousDoctorName ? this.previousDoctorName : '');
    this.previousDoctorName = '';
  }

}
