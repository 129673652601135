import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationUser } from '../../model/applicationUser';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'chat',
  providers: [],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})



export class ChatComponent implements OnInit {

  model1: NgbDateStruct;
  model2: NgbDateStruct;
  model: ApplicationUser;
  user: any;

  constructor(private _router: Router) { }

  ngOnInit() {

    $('.left-bar-toggle').on('click', function (e) {
      $('#wrapper').toggleClass('left-bar-enabled');
    });

    $('.right-bar-toggle').on('click', function (e) {
      $('#wrapper').toggleClass('right-bar-enabled');
    });

    if (!sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C') || !sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF')) {
      this._router.navigate(['/']);
    }
  }
}
