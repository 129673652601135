export class User {
    username: String;
    password: String;
    firstName: String;
    lastName: String;
    email: String;
    profileImage: String;
    status: Boolean;
    isAdmin: Boolean;
    isDsaUser: Boolean;
    nif: String;
    mobileNumber: String;
    resetToken: String;
};