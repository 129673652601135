import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctions } from 'src/app/common/common-functions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { USERS } from 'src/app/common/endpoints';
import { AuthModel } from 'src/app/model/auth-model';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/service/language.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CenterSelectionPopupComponent } from '../center-selection-popup/center-selection-popup.component';
import { ClinicService } from '../../service/clinic.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dsa-login',
  templateUrl: './dsa-login.component.html',
  styleUrls: ['./dsa-login.component.css']
})
export class DsaLoginComponent implements OnInit {

  formGroup: UntypedFormGroup;
  modalRef: NgbModalRef;

  constructor(
    private _uiLoaderService: NgxUiLoaderService,
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private modalService: NgbModal,
    private _languageService: LanguageService,
    private _clinicService: ClinicService,
    private router: Router,
    private translate: TranslateService) {
    this.initForm();
  }

  ngOnInit() {
    sessionStorage.clear();
  }

  initForm() {
    this.formGroup = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['']
    });
  }

  private openCenterModal(userId: string) {
    const modalRef = this.modalService
      .open(CenterSelectionPopupComponent, {
        centered: true,
        size: 'sm'
      });
    modalRef.componentInstance.dsaUserId = userId;
    this.listenProceedEvent(modalRef);
  }

  private listenProceedEvent(modalRef: NgbModalRef) {
    modalRef.componentInstance.proceed
      .subscribe((result: { centerGuid: string }) => {
        if (result && this._authService.userData) {
          this.grantAccess({
            userId: this._authService.userData.id,
            userName: this._authService.userData.name,
            centerGuid: result.centerGuid
          });
        }
      });
  }

  async onClickSignIn() {
    try {
      if (this.formGroup.invalid) { CommonFunctions.showInvalidFormControls(this.formGroup); } else {
        this._uiLoaderService.start();
        const credentials = this.formGroup.value as AuthModel;
        if (credentials) {
          credentials.password = credentials.password ? credentials.password : '';
          const response = await this._authService
            .login(USERS.LOGIN, credentials)
            .toPromise();

          if (response.body.status === 200) {
            const token = response.body.data as string;
            sessionStorage.CDFEE2BC43D63CAEAA3B169AD31E966C = token;
            this._authService.setLoggedUser();

            if (this._authService.userData && this._authService.userData.centerCount > 1) {
              this.openCenterModal(this._authService.userData.id);
            } else {
              if (this._authService.userData.defaultCenterGuid) {
                this.grantAccess({
                  userId: this._authService.userData.id,
                  userName: this._authService.userData.name,
                  centerGuid: this._authService.userData.defaultCenterGuid,
                });
              }
            }
          } else {
            if (response.body.status === 403) {
              const toastError = await this._languageService.getTranslation('authenticate.deactivatedUser');
              this._toastrService.error(toastError);
            } else if (response.body.status === 404) {
              const toastError = await this._languageService.getTranslation('authenticate.noActiveCenters');
              this._toastrService.error(toastError);
            } else {
              const toastError = await this._languageService.getTranslation('authenticate.invalidCredentials');
              this._toastrService.error(toastError);
            }
            this.formGroup.reset();
          }
        }
      }
    } catch (error) { console.log(error); } finally {
      this._uiLoaderService.stop();
    }
  }

  private async grantAccess(data: {
    userId: string,
    userName: string,
    centerGuid: string
  }) {
    try {
      if (data) {
        sessionStorage.setItem('1F4E6C2A587EAD3371E85EC3C08CFFCF', 'clinic');
        sessionStorage.setItem('2801636AC65D840F1735DC0833A61B69',
          JSON.stringify({ name: data.userName, code: data.userId }));

        const center = await this._clinicService
          .getClinicByGuid(data.centerGuid).toPromise();

        if (center) {
          sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(center));
          sessionStorage.setItem('4CDC84B1E0607D91E3C7BBDE07EECC6A', center._id);
          let language = 'en';
          if (center.language) {
            language = center.language;
          } else {
            language = this.translate.getBrowserLang();
          }
          sessionStorage.setItem('currentLang', language); // save language in session storage
        }
        this.router.navigate(['/general-chat']);
      } else {
        console.log('invalid jwt');
        this.router.navigate(['/']);
      }
    } catch (error) { console.log(error); }
  }
}
