<div id="no-padding" class="container app">

  <div class="row app-one">

    <div class="col-xs-12 col-sm-4 col-md-3  col-lg-3 side">

      <div class="side-one">

        <chat-side-bar></chat-side-bar>

      </div>
      <!-- Sidebar End -->
    </div>
    <!-- New Message Sidebar End -->

    <!-- Conversation Start -->

    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9 conversation">


      <chat-heading></chat-heading>

      <message-box></message-box>

      <chat-rightbar></chat-rightbar>
      <div class="overlay-right"></div>

    </div>

    <!-- Modal -->
    <div class="modal fade" id="addworkModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Work
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Search Lab</label>
                  <input type="text" class="form-control" placeholder="Search Lab" aria-label="Search Lab"
                    aria-describedby="basic-addon1">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Work Id</label>
                  <input type="text" class="form-control" placeholder="Work Id" aria-label="Work Id"
                    aria-describedby="basic-addon1">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Created Date</label>
                  <div class="input-group calendar-wrap">
                    <input class="form-control calendar-input" placeholder="yyyy-mm-dd"
                           name="dp" [(ngModel)]="model1" ngbDatepicker #c="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button"><span class="glyphicon glyphicon-calendar"></span></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Delivery Date</label>
                  <div class="input-group calendar-wrap">
                    <input class="form-control calendar-input" placeholder="yyyy-mm-dd"
                           name="dp" [(ngModel)]="model2" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><span class="glyphicon glyphicon-calendar"></span></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Doctor</label>
                  <input type="text" class="form-control" placeholder="Doctor" aria-label="Doctor"
                    aria-describedby="basic-addon1">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Patient data</label>
                  <textarea></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Work description</label>
                  <textarea></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            
          </div>
        </div>
      </div>
    </div>
    <!-- Conversation End -->
    <div class="overlay-left">
      <chat-leftbar></chat-leftbar>
    </div>
  </div>
  <!-- App One End -->
</div>


<!-- App End -->