import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthorizationService {

    private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION;

    private options: any;

    constructor(private http: HttpClient) { }

    getApplicationUser(app_key: string): any {
        return this.http.get(`${this.url}${app_key}`, this.options)
            .pipe(map(response => response), catchError(err => of(err)));
    }

    getSessionToken(webTicket: string) {     
        if (webTicket) {
            this.options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': ApiConnection.BASE_URL,
                    'Authorization': webTicket
                })
              };
        }
        else {
            this.options = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': ApiConnection.BASE_URL
                })
              };
        }

        return this.http.post(`${this.url}authentication/dentbox`, null, this.options)
            .pipe(map(response => response), catchError(err => of(err)));
    }

}

