export const CLINICS = {
    GET_CENTER_BY_GUID: 'clinics/getbyguid/'
};

export const LABS = {
    GET_CENTER_BY_GUID: 'labs/getbyguid/',
    GET_LABS_BY_CENTER_GUID: 'labs/getbycenterguid/',
};

export const WORKS = {
    GET_WORK_LIST_BY_CENTER_ID: 'works/getbycenterid/',
    SAVE_WORK: 'works/savework/',
    UPDATE_WORK: 'works/updatework/',
    DELETE_WORK: 'works/deletework/',
};

export const USERS = {
    LOGIN: 'users/login/',
    FORGOT: 'users/forgot/',
    RESET: 'users/resetpassword/'
};
