import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Ticket } from 'src/app/model/ticket';
import { ClinicService } from '../../service/clinic.service';
import { LabService } from '../../service/lab.service';
import { Clinic } from '../../model/clinic';
import { Lab } from '../../model/lab';
import { AuthorizationService } from '../../service/authorization.service';
import { ChatService } from '../../service/chat.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthorizationService,
    private clinicService: ClinicService, private labService: LabService, private chatService: ChatService) { }

  ngOnInit() { this.authenticate(); this.chatService.setLabels(); }

  private async authenticate() {
    try {
      const ticket = this.route.snapshot.queryParamMap.get('ticket');
      if (ticket) {
        const result = await this.authService.getSessionToken(ticket).toPromise();
        if (result.status === 200) {
          const sessionToken = result.data;
          const jwt: Ticket = jwt_decode(atob(sessionToken));
          if (jwt) {
            let center: Clinic | Lab;
            sessionStorage.setItem('CDFEE2BC43D63CAEAA3B169AD31E966C', sessionToken);
            sessionStorage.setItem('1F4E6C2A587EAD3371E85EC3C08CFFCF', jwt.openingMode);
            sessionStorage.setItem('2801636AC65D840F1735DC0833A61B69',
              JSON.stringify({ name: jwt.user, code: jwt.userCode }));

            if (jwt.openingMode === 'lab') {
              center = await this.labService.getClinicByGuid(jwt.centerGuid).toPromise();
            } else if (jwt.openingMode === 'clinic') {
              center = await this.clinicService.getClinicByGuid(jwt.centerGuid).toPromise();
            }
            if (center) {
              sessionStorage.setItem('50EE60E5468D8FC43984228303D24EE9', JSON.stringify(center));
              sessionStorage.setItem('4CDC84B1E0607D91E3C7BBDE07EECC6A', center._id);
            }
            if (jwt.workGuid) {
              sessionStorage.setItem('5EC358E33FC855F0268D055B457B1D68', jwt.workGuid);
              this.router.navigate(['/work-chat']);
            } else {
              this.router.navigate(['/general-chat']);
            }
          } else {
            console.log('invalid jwt');
            this.router.navigate(['/']);
          }
        } else {
          sessionStorage.clear();
          this.router.navigate(['unauthorized']);
        }
      } else {
        this.router.navigate(['/']);
      }
    } catch (error) { }
  }
}
