import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { Clinic } from '../model/clinic';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClinicService {

    private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION + 'clinics';
    selectedUser: Clinic;

    constructor(private http: HttpClient) { }

    public getClinics(): any {
        return this.http.get(`${this.url}`);
    }

    public getClinicById(id: string): any {
        return this.http.get(`${this.url}/${id}`);
    }

    public getClinicByGuid(guid: string): any {
        return this.http.get(`${this.url}/getbyguid/${guid}`);
    }

    putClinic(clinic: Clinic): any {
        return this.http.put(ApiConnection.BASE_URL + ApiConnection.API_VERSION + `clinics/${clinic._id}`, clinic);
    }

}

