<div class="wrapper pop fadeInDown">
   <div class="logo">
      <img src="../../../assets/images/dentbox_logo.png" alt="Dentbox">
   </div>
   <div id="formContent">
      <!-- Tabs Titles -->
      <!-- Icon -->
      <div class="fadeIn first">
         <h3>{{ 'authenticate.recover_your_password' | translate }}</h3>
      </div>
      <!-- Login Form -->
      <form autocomplete="off" [formGroup]="forgotFormGroup" #forgotForm="ngForm">
      <div class="form-group">
         <label>{{ 'authenticate.your_email_address' | translate }}</label>
         <input id="email" type="email" class="fadeIn second" autocomplete="off" [(ngModel)]="rpvm.email" formControlName="email"
         pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
         <div *ngIf="forgotFormGroup.get('email').dirty && forgotFormGroup.get('email').errors?.pattern">
            <span class="error error-signin">{{'common.required' | translate }}</span>
         </div>
         <div *ngIf="forgotFormGroup.get('email').dirty && forgotFormGroup.get('email').errors?.required">
            <span class="error error-signin">{{'common.required' | translate }}</span>
         </div>
      </div>
      <button class="fadeIn fourth login" type="button" (click)="recoverPassword(forgotForm, $event)" [disabled]="forgotFormGroup.invalid">
         {{ 'authenticate.reset_password' | translate }}
      </button>
      </form>
      <div id="formFooter">
         <a [routerLink]="['/dsa-login']">{{ 'authenticate.back_to_login' | translate }}</a>
      </div>
   </div>
</div>