import { UntypedFormGroup } from "@angular/forms";

export function ShowInvalidFormControls(formGroup: UntypedFormGroup) {
    if (formGroup !== undefined) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsDirty({ onlySelf: true });
            control.markAsTouched({ onlySelf: true });
        });
    }
}