import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { ChatService } from './chat.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MessageService {

    private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION + this._chatService.Path.MESSAGE;
    private options: any;

    constructor(private http: HttpClient, private _chatService: ChatService) {
        this.setHeaders();
    }

    private setHeaders(): void {
        if (sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C')) {
            this.options = {
                headers: new HttpHeaders({
                    'Authorization': sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C')
                })
              };
        }
    }

    public getMessages(): any {
        return this.http.get(`${this.url}`, this.options);
    }

    public deleteMessageById(id) {
        return this.http.delete(`${this.url}/${id}`, this.options);
    }

    public updateMessageById(id, message) {
        return this.http.put(`${this.url}/${id}`, message, this.options);
  }

  public updateMessageStatus(userId, dateTime, status, workId?:any) {
    return this.http.put(`${this.url}/${userId}/${dateTime}/${status}/${workId}`, null, this.options);
  }

    public addMessage(message) {
        return this.http.post(`${this.url}`, message, this.options);
    }

    public uploadAttachments(attachmentList) {

        const head = new Headers();
        head.append('Content-Type', 'multipart/form-data');
        head.append('Accept', 'application/json');
        this.options = { headers: head }

        return this.http.post(`${ApiConnection.FILE_SERVER_URL + ApiConnection.FILE_VERSION}
        ${this._chatService.Path.UPLOAD}`, attachmentList, this.options);
    }
}

