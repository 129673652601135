import { Injectable } from '@angular/core';
import { ApiConnection } from './apiConnection';
import { Observable } from 'rxjs';
import { Work } from '../model/work';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class WorkService {
    workSubject = new Subject();
    workViewSubject = new Subject();

    private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION + 'works';

    constructor(private http: HttpClient) { }

    public getWorks(): any {
        return this.http.get(`${this.url}`);
    }

    public getWorksById(id: string): Observable<any> {
        return this.http.get(`${this.url}/${id}`);
    }

    public getWorkByGuid(guid: string): Observable<any> {
        return this.http.get(`${this.url}/getbyguid/${guid}`);
    }

    public updateWorkById(id: string, work: Work): Observable<any> {
        return this.http.put(`${this.url}/${id}`, work);
    }
}

