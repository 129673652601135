<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{(isEdit ? 'work.editWork' : 'work.addWork') | translate}}</h4>
</div>
<div class="modal-body add-work-popup">
  <form [formGroup]="workFormGroup">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>{{ 'work.search_lab' | translate }}</label>
          <nova-search class="modreservation-client-search" [items]="labList" autocomplete="off"
            [options]="{ key:'centerDescription', display:'centerDescription' }" [skipAccents]="true"
            [inputCss]="'form-control form-control-sm'" [optionCss]="'article-css modreservation-client-css'"
            [control]="workFormGroup.controls.labName" [elementId]="'client-name'"
            [placeholder]="'common.type_here' | translate" (onItemSelected)="onSelectLab($event)"
            (onClearText)="onSelectLab()">
          </nova-search>
        </div>
        <div
          *ngIf="workFormGroup.get('labName').touched && !workFormGroup.get('labName').errors?.isValid && workFormGroup.get('labName').errors != null">
          <span class="error-msg">{{ 'common.required' | translate }}</span>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'resource.workId' | translate }}</label>
          <input type="text" class="form-control" placeholder="Work Id" aria-label="Work Id"
            aria-describedby="basic-addon1" formControlName="guid">
        </div>
      </div> -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'work.doctor' | translate }}</label>
          <input type="text" class="form-control" aria-label="doctor" aria-describedby="basic-addon1"
            formControlName="collaborator" (focus)="onFocus(true)" (focusout)="onFocus(false)">
          <div *ngIf=previousDoctorName>
            <ul class="prev-selected-doc">
              <li><a (mousedown)="onSelectDoctor()">Doctor{{previousDoctorName}}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'work.patient_data' | translate }}</label>
          <input type="text" class="form-control" aria-label="Patient Data" formControlName="patientName">
        </div>
        <div
          *ngIf="workFormGroup.get('patientName').touched && !workFormGroup.get('patientName').errors?.isValid && workFormGroup.get('patientName').errors != null">
          <span class="error-msg">{{ 'common.required' | translate }}</span>
        </div>
      </div>
      <div class="col-md-2 pr-0">
        <label class="col-form-label">{{ 'resource.patientHistory' | translate }}</label>
        <div class="form-group">
          <input type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <!-- <label>{{ 'work.createdDate' | translate }}</label> -->
          <label>{{ 'resource.orderDate' | translate }}</label>
          <div class="input-group calendar-wrap">
            <input id="entry-date" placeholder="{{ commonUiService.GetPlaceHolderValue() }}" ngbDatepicker
              autocomplete="off" formControlName="entryDate" #entryDate="ngbDatepicker" (click)="entryDate.toggle();"
              class="form-control calendar-input">
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>{{ 'resource.deliverDate' | translate }}</label>
          <div class="input-group calendar-wrap">
            <input id="delivery-date" type="text" placeholder="{{ commonUiService.GetPlaceHolderValue() }}"
              ngbDatepicker autocomplete="off" formControlName="deliveryDate" #deliveryDate="ngbDatepicker"
              (click)="deliveryDate.toggle();" class="form-control calendar-input">
          </div>
        </div>
      </div>
      <div class="col-md-2 pr-0">
        <label class="col-form-label">{{ 'resource.deliverTime' | translate }}</label>
        <div class="form-group">
          <input type="text" class="form-control" mask="Hh:m0" placeholder="hh:mm">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{ 'work.work_description' | translate }}</label>
          <input type="text" class="form-control" aria-label="Work Description" formControlName="description">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label> {{ 'resource.color' | translate }}</label>
          <input type="text" class="form-control" placeholder="{{ 'resource.color' | translate }}">
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label>{{ 'resource.teeth' | translate }}</label>
          <input type="text" class="form-control" placeholder="{{ 'resource.teeth' | translate }}">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label> {{ 'resource.note' | translate }}</label>
          <textarea rows="3" type="text" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="col-form-label"> {{ 'resource.document' | translate }}</label>
        <div class="form-group">
          <input class="form-control" type="file">
        </div>
      </div>
      <!-- attched doc name -->
     <!-- <div class="col-md-12">
      <div class="document-list">
        <ul>
          <li>
            <div class="row">
              <div class="col-md-8">
                doc-1
              </div>
              <div class="col-md-4">
                <em  class="fa fa-trash float-right"></em>                 
              </div>
            </div>
          </li>
        </ul>
      </div>
     </div> -->
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="saveWork()">{{ 'common.save' | translate }}</button>
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onCloseModal()">{{ 'common.cancel' |
    translate }}</button>
</div>