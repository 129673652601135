export class Message {
    _id: any;
    messageId: string;
    workId: string;
    owner: string;
    senderId: string;
    recieverId: string;
    creationDateTime: string;
    readDateTime: string;
    deliveredDateTime: string;
    text: string;
    attachments: any;
    state: string;
    isDateTimeEqual: boolean;
    creationDate: string;
    isUnseen: boolean;
    timeDiff: string;
    readDate: string;
    readTime: string;
    safeUrlList: any;
    loggedUserName: string;
    loggedUserCode: string;
}
