import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'linear-gradient(120deg, #00e4d0, #5983e8)',
    bgsOpacity: 0.5,
    bgsPosition: POSITION.centerCenter,
    bgsSize: 60,
    bgsType: SPINNER.squareLoader,
    fgsColor: '#ffff',
    fgsPosition: POSITION.centerCenter,
    fgsSize: 35,
    fgsType: SPINNER.squareLoader,
    pbColor: 'linear-gradient(120deg, #00e4d0, #5983e8)',
    pbDirection: PB_DIRECTION.leftToRight,
    pbThickness: 5,
    textColor: '#FFFFFF',
    textPosition: POSITION.centerCenter
};