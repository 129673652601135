import { Component, OnInit, OnDestroy } from '@angular/core';
import { ClinicService } from '../../service/clinic.service';

@Component({
  selector: 'home',
  providers: [ClinicService],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  ngOnInit() { sessionStorage.clear(); }
  ngOnDestroy() { }
}
