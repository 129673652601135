import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const ticket: string = sessionStorage.getItem('CDFEE2BC43D63CAEAA3B169AD31E966C');
        if (ticket) {
            request = request.clone({ headers: request.headers.set('Authorization', ticket) });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) { }
                return event;
            }),
            catchError(error => {
                //  console.clear();
                console.log('Error:: dentbox : ' + error.statusText);
                if (error.status === 401) {
                    this.handleAuthError();
                    // return of(error);
                }
                throw error;
            }));
    }

    private handleAuthError() {
        sessionStorage.clear();
        this.router.navigate(['unauthorized']);
    }
}
