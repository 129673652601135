import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    // tslint:disable-next-line: variable-name
    constructor(private _route: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (sessionStorage['CDFEE2BC43D63CAEAA3B169AD31E966C'] && sessionStorage['1F4E6C2A587EAD3371E85EC3C08CFFCF']
            && sessionStorage['50EE60E5468D8FC43984228303D24EE9'] && sessionStorage['4CDC84B1E0607D91E3C7BBDE07EECC6A']) {
            return true;
        } else {
            this._route.navigate(['/']);
            return false;
        }
    }
}

