import { Injectable } from '@angular/core';
import { Work } from '../model/work';
import * as Rx from 'rxjs';
import { Message } from '../model/message';
import { Lab } from '../model/lab';
import { Clinic } from '../model/clinic';

const BehaviorSubject = new Rx.BehaviorSubject<Work>(new Work());
const BehaviorSubjectForMessageList = new Rx.BehaviorSubject<Message[]>([]);
const BehaviorSubjectForLabList = new Rx.BehaviorSubject<Lab[]>([]);
const BehaviorSubjectForUniqueDateList = new Rx.BehaviorSubject<string[]>([]);
const BehaviorSubjectForClinicList = new Rx.BehaviorSubject<Clinic[]>([]);
const BehaviorSubjectForHomePage = new Rx.BehaviorSubject(1);
const BehaviorSubjectForUnSeenMessageList = new Rx.BehaviorSubject<Message[]>([]);

@Injectable()
export class ChatPublishService {
    private messageSource = BehaviorSubject;
    currentMessage = this.messageSource.asObservable();

    private messageListSource = BehaviorSubjectForMessageList;
    currentMessageList = this.messageListSource.asObservable();

    private uniqueDateListSource = BehaviorSubjectForUniqueDateList;
    currentUniqueDateList = this.uniqueDateListSource.asObservable();

    private labListSource = BehaviorSubjectForLabList;
    currentLabList = this.labListSource.asObservable();

    private clinicListSource = BehaviorSubjectForClinicList;
    currentClinicList = this.clinicListSource.asObservable();

    private ishomeSource = BehaviorSubjectForHomePage;
    isHome = this.ishomeSource.asObservable();
    private unSeenMessageListSource = BehaviorSubjectForUnSeenMessageList;
    currentUnSeenMessageList = this.unSeenMessageListSource.asObservable();

    currentWork: Work;

    setWork(work: Work) {
        this.messageSource.next(work);
        this.currentWork = work;
    }

    setIsHome() {
        this.ishomeSource.next(0);
    }

    setMessageList(list: any[]) {
        this.messageListSource.next(this.sortByWorkCreationDate(list));
        this.setUniqueDateList(this.getUniqueCreationDateList(list));
    }

    setUnSeenMessageList(list: any[]) {
        this.unSeenMessageListSource.next(list);
    }

    setLabList(list: any[]) {
        this.labListSource.next(list);
    }

    setClinicList(list: any[]) {
        this.clinicListSource.next(list);
    }

    setUniqueDateList(list: any[]) {
        this.uniqueDateListSource.next(list);
    }

    sortByWorkCreationDate(array): any {
        return array.sort((a: Message, b: Message) => {
            const dateA = new Date(a.creationDateTime);
            const dateB = new Date(b.creationDateTime);
            return dateB.getTime() - dateA.getTime();
        });
    }

    getUniqueCreationDateList(array) {
        if (array) {
            const dateList = array.map(data => data.creationDateTime.substring(0, 10));
            const uniqueDateList = dateList.filter((x, i, a) => x && a.indexOf(x) === i);
            return uniqueDateList;
        } else {
            return [];
        }
    }
}

