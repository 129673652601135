import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { ngxUiLoaderConfig } from './common/global';
import { LanguageService } from './service/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  isOnline: boolean = navigator.onLine;

  config: NgxUiLoaderConfig;

  constructor(
    private translate: TranslateService,
    private _languageService: LanguageService) {
    this.config = ngxUiLoaderConfig;
    let language = 'en';

    if (sessionStorage.getItem('currentLang')) {
      language = sessionStorage.getItem('currentLang');
    } else {
      language = this.translate.getBrowserLang();
    }
    sessionStorage.setItem('currentLang', language); // save language in session storage
    this.translate.use(language);
  }
}
