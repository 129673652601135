export class Work {
    _id: string;
    workNo: string;
    clinicId: string;
    labId: string;
    profileImagePath: string;
    observation: string;
    typeOfWork: string;
    clinicReference: string;
    labReference: string;
    deliveredDate: string;
    collaborator: string;
    workReferenceNo: string;
    color: string;
    workItemLocation: string;
    entryDateOnly: string;
    timeDiff: string;
    clinicName: string;
    labName: string;
    convertedEntryDate: string;

    guid: string;
    description: string;
    patientHistoryNumber: string;
    patientName: string;
    patientSurname: string;
    collaboratorCode: string;
    collaboratorDescription: string;
    entryDate: string;
    deliveryDate: string;
    comodin1: string;
    comodin2: string;
    state: string;

    name: string;
    isAttachmentsAvailable: boolean;
}

