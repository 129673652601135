import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConnection } from './apiConnection';
import { Attachment } from '../model/attachment';

@Injectable()
export class UploadService {

  private _url: string = ApiConnection.FILE_SERVER_URL + ApiConnection.FILE_VERSION;

  constructor(private http: HttpClient) { }

  public uploadFiles(url: string, fileList: any, workId: string, attachmentList: Attachment[]) {
    if (url && fileList.length > 0 && workId && attachmentList.length > 0) {
      const formData = new FormData();
      fileList.forEach(file => {
        const item: Attachment = (attachmentList.filter((x, i, a) => x.originalName === file[0].name))[0];
        if (item) {
          const newName: string = item.newName;
          if (newName !== undefined) { formData.append(newName, file[0], newName); }
        }
      });
      formData.append('work', workId);
      const params = new HttpParams();
      const options = {
        params: params,
        reportProgress: false,
      };
      const req = new HttpRequest('POST', url, formData, options);
      return this.http.request(req);
    }
  }

  public uploadProfilePicture(url: string, file: File, type: string, newName: string): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('uploadProfilePicture', file, newName);
    formData.append('type', type);
    const params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
    };
    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

  public downloadFile(file: String, workId: string) {
    const body = { filename: file, work: workId };
    return this.http.post(this._url + 'download', body, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
}
