<!-- Right Sidebar -->
<div class="side-bar right-bar margin-top-70 width-new">
    <div class="nicescroll">
        <ul class="nav nav-tabs tabs">
            <li class="active tab">
                <a href="#taskprofile" data-toggle="tab" aria-expanded="false">
                    <span class="visible-xs">
                        <em class="fa fa-user"></em>
                    </span>
                    <em class="fa fa-angle-right fa-2x fastyle right-bar-toggle waves-effect waves-light"> </em>
                    <span class="hidden-xs"> {{ work.description }} </span>
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div id="taskprofile" class="tab-pane fade in active text-algn-c lettertype">
                <div class="btn-group" *ngIf="work.state == 'pending'">
                    <button class="delete-work"><em class="fa fa-trash" (click)="onDeleteWork(work)"></em></button>
                    <button class="edit-work" (click)="onOpenEditWorkModal(work)"><em class="fa fa-edit"></em></button>
                </div>
                <div class="text-center">
                    <img *ngIf="work.profileImagePath" src="{{ work.profileImagePath }}" height="100" width="100"
                        class="img-responsive img-circle" alt="Work Profile Image Path">
                </div>
                <div class="text-center">
                    <p *ngIf="!work.profileImagePath" class="avatar-lg" [ngClass]="[
                    work.state === 'active' ? 'active-avatar' : '',
                    work.state === 'pending' ? 'pending-avatar' : '',
                    work.state === 'closed' ? 'closed-avatar' : '']"></p>
                </div>
                <h4 class="text-primary patient-txt-color" *ngIf="clinicCode !== undefined">
                    {{ (work.patientName ? work.patientName : '') + ' ' + (work.patientSurname ? work.patientSurname :
                    '') }}</h4>
                <h4 class="text-primary patient-txt-color" *ngIf="clinicCode === undefined && !isHidePatient">
                    {{ (work.patientName ? work.patientName : '') + ' ' + (work.patientSurname ? work.patientSurname :
                    '') }}</h4>
                <div class="row margin-t">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-inbox icon-margin"
                            aria-hidden="true"></em>{{
                        'resource.workId' | translate }}</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">{{ work.guid }}
                    </div>
                </div>
                <div class="row margin-t">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-stethoscope icon-margin"></em>{{
                        'resource.collaborator' | translate }}</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">
                        {{ (work.collaboratorDescription) ? (work.collaboratorDescription + ( work.collaboratorCode ?
                        '-' : '')) : '' }} {{
                        work.collaboratorCode }}</div>
                </div>

                <div class="row margin-t">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-calendar icon-margin"></em>{{ 'work.createdDate' | translate }}</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">
                        {{ work.entryDate | date: 'dd/MM/yyyy'}}</div>
                </div>

                <div class="row margin-t">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em
                            class="fa fa-calendar icon-margin"></em>{{ 'resource.deliverDate' | translate }}</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">
                        {{ work.deliveryDate | date: 'dd/MM/yyyy'}}</div>
                </div>
                <hr />
                <div class="more-info">
                    <div class="loop">
                        <h4>{{'work.patient_data' | translate}}</h4>
                        <p>{{ (work.patientName ? work.patientName : '') + ' ' + (work.patientSurname ?
                            work.patientSurname : '') }}</p>
                        <p>{{ (work.patientHistoryNumber)? work.patientHistoryNumber : '' }}</p>
                    </div>
                    <div class="loop">
                        <h4>{{'work.work_description' | translate}}</h4>
                        <p>{{ work.workReferenceNo ? work.workReferenceNo : '' }}</p>
                        <p>{{ work.description ? work.description : '' }} {{work.state ? '(' : '' }}
                            <ng-container *ngIf="work.state === 'pending'">
                                {{ 'resource.pending' | translate }}
                            </ng-container>
                            <ng-container *ngIf="work.state === 'active'">
                                {{ 'resource.active' | translate }}
                            </ng-container>
                            <ng-container *ngIf="work.state === 'closed'">
                                {{ 'resource.closed' | translate }}
                            </ng-container>
                            {{work.state ? ')' : '' }}
                        </p>
                    </div>
                </div>
            </div>
            <h4 class="eye-style">
                <p> <em class="fa fa-eye "></em>{{ 'resource.observations' | translate }}</p>
                <p><a data-toggle="collapse" href="#collapseExample"><i class="fa fa-angle-down"
                            aria-hidden="true"></i></a></p>
                <div id="collapseExample" class="collapse">
                    <div class="card card-body">
                        <p><small class="text-muted">{{ work.observation }}</small></p>
                    </div>
                </div>
                <br />
            </h4>
            <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 attch-style">
                    <span> {{ 'resource.attachments' | translate }}
                        ({{ attachmentList ? attachmentList.length : 0 }})</span>
                </div>
            </div>
            <div class="row">
                <ng-container *ngFor="let item of attachmentList">
                    <div class="col-sm-3 col-xs-3 col-md-3 col-lg-3 attachment-style inside-image-radius">
                        <a *ngIf="item && item.extension === '.png' || item.extension === '.jpg' || item.extension === '.jpeg' || item.extension === '.gif'"
                            href="{{ FILE_SERVER_URL + work._id + '/' + item.url }}" target="_blank">
                            <img title="{{ 'resource.previewAttach' | translate }}"
                                class="img-thumbnail img-responsive img-rightside" alt="Attachment"
                                src="{{ FILE_SERVER_URL + work._id + '/' + item.url }}">
                            <p class="text-center">{{ item.originalName }}</p>
                        </a>
                        <a *ngIf="item && item.extension === '.txt' || item.extension === '.docx' || item.extension === '.pdf' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc'"
                            href="{{ FILE_SERVER_URL + work._id + '/' + item.url }}" target="_blank">
                            <img title="{{ 'resource.previewAttach' | translate }}"
                                class="img-thumbnail img-responsive img-rightside" alt="Attachment"
                                [src]="documentThumbnails">
                            <p class="text-center">{{ item.originalName }}</p>
                        </a>
                        <a *ngIf="item && item.extension === '.mp4' || item.extension === '.mov'"
                            href="{{ FILE_SERVER_URL + work._id + '/' + item.url }}" target="_blank">
                            <img title="{{ 'resource.previewAttach' | translate }}"
                                class="img-thumbnail img-responsive img-rightside" alt="Attachment"
                                src="{{ FILE_SERVER_URL + work._id + '/' + item.thumbnailUrl }}">
                            <p class="text-center">{{ item.originalName }}</p>
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- /Right-bar -->