import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiConnection } from './apiConnection';
import { Message } from '../model/message';
import { Clinic } from '../model/clinic';
import { Work } from '../model/work';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LABS, WORKS } from '../common/endpoints';

const MessageListBehaviorSubject = new BehaviorSubject<Message[]>([]);
const SelectedWorkBehaviorSubject = new BehaviorSubject<Work>(new Work());
const UniqueDateListBehaviorSubject = new BehaviorSubject<string[]>([]);
const HomePageBehaviorSubject = new BehaviorSubject(1);
const BehaviorSubjectForLastMsg = new BehaviorSubject(null);
const ApplicationUserBehaviorSubject = new BehaviorSubject(null);
const LatestBehaviorSubject = new BehaviorSubject(1);
const OldestBehaviorSubject = new BehaviorSubject(1);
const IsLatestBehaviorSubject = new BehaviorSubject(1);
const IsOldestBehaviorSubject = new BehaviorSubject(0);
const LastMessgeBehaviorSubject = new BehaviorSubject<Message>(new Message());
const TranslateLabelBehaviorSubject = new BehaviorSubject<string[]>([]);

@Injectable()
export class ChatService {
    private url: string = ApiConnection.BASE_URL + ApiConnection.API_VERSION;
    private options: any;
    private headers: Headers;
    private messageListSource = MessageListBehaviorSubject;
    private selectedWorkSource = SelectedWorkBehaviorSubject;
    private uniqueDateListSource = UniqueDateListBehaviorSubject;
    private isHomeSource = HomePageBehaviorSubject;
    private lastmsgSource = BehaviorSubjectForLastMsg;
    private applicationUserSOurce = ApplicationUserBehaviorSubject;
    private latestource = LatestBehaviorSubject;
    private oldestSource = OldestBehaviorSubject;
    private islatestource = IsLatestBehaviorSubject;
    private isoldestSource = IsOldestBehaviorSubject;
    private lastMsgObjectSource = LastMessgeBehaviorSubject;
    private tanslateLabelSource = TranslateLabelBehaviorSubject;

    public translateLabels: string[] = [];

    public Path: any = {
        LAB: 'labs',
        CLINIC: 'clinics',
        MESSAGE: 'messages',
        WORK: 'works',
        UPLOAD: 'upload'
    };

    public ResourcePath: any = {
        IMAGE_THUMBNAILS: 'assets/images/thumbnails-image.png',
        DOCUMENT_THUMBNAILS: 'assets/images/document-image.png'
    };

    // observables
    public currentMessageList = this.messageListSource.asObservable();
    public currentSelectedWork = this.selectedWorkSource.asObservable();
    public currentUniqueDateList = this.uniqueDateListSource.asObservable();
    public isHome = this.isHomeSource.asObservable();
    public lastmsgDate = this.lastmsgSource.asObservable();
    public currentApplicationUser = this.applicationUserSOurce.asObservable();
    public MessageMergeTime = 3;
    public _messageList: Message[] = [];
    public latest = this.latestource.asObservable();
    public oldest = this.oldestSource.asObservable();
    public islatest = this.islatestource.asObservable();
    public isoldest = this.isoldestSource.asObservable();
    public lastMsgObj = this.lastMsgObjectSource.asObservable();
    public translateLabel = this.tanslateLabelSource.asObservable();

    constructor(private http: HttpClient, public datepipe: DatePipe,
        private translate: TranslateService) { }

    /* set application user object as observable */

    public setCurrentApplicationUser(user: Clinic) {
        this.applicationUserSOurce.next(user);
    }
    /* eof application user */

    // get all labs
    public getLabs() {
        return this.http.get(`${this.url}${this.Path.LAB}`)
            .pipe(catchError(err => of(err)));
    }

    // get all clinics
    public getClinics(): Observable<Clinic[]> {
        return this.http.get(`${this.url}${this.Path.CLINIC}`)
            .pipe(catchError(err => of(err)));
    }

    // get all works
    public getWorks(): Observable<Work[]> {
        return this.http.get(`${this.url}${this.Path.WORK}`)
            .pipe(catchError(err => of(err)));
    }

    public getWorksByCenterId(centerId: string): Observable<Work[]> {
        return this.http.get(`${this.url}${this.Path.WORK}/getbycenterid/${centerId}`)
            .pipe(catchError(err => of(err)));
    }

    // get all messages
    public getAllMessages(): Observable<Message[]> {
        return this.http.get(`${this.url}${this.Path.MESSAGE}`)
            .pipe(catchError(err => of(err)));
    }

    // get all messages related to work
    public getMessages(workId: string): Observable<Message[]> {
        return this.http.get(`${this.url}${this.Path.MESSAGE}/${workId}`)
            .pipe(catchError(err => of(err)));
    }

    // set and publish message list
    async setAndPublishMessage(work: Work, latest, oldest): Promise<void> {
        this.getRelatedMessageList((data) => {
            this.messageListSource.next(this.sortByWorkCreationDate(data));
            this.setUniqueDateList(this.getUniqueCreationDateList(data));
        }, work['_id'], latest, oldest);
    }

    // publish message list
    public setMessageList(list: any[]) {
        this.messageListSource.next(this.sortByWorkCreationDate(list));
        this.setUniqueDateList(this.getUniqueCreationDateList(list));
    }

    // publish currently selected work
    public setSelectedWork(work: Work) {
        this.selectedWorkSource.next(work);
        this.lastmsgSource.next(null);
    }

    // publish unique date list
    public setUniqueDateList(list: string[]) {
        this.uniqueDateListSource.next(list);
    }

    // publish unique date list
    public setLastMsgObject(msg: Message) {
        this.lastMsgObjectSource.next(msg);
    }

    // filter related messages
    public getRelatedMessageList(callback: (data) => void, work_id, latest, oldest) {
        this.islatestource.next(0);
        this.isoldestSource.next(0);

        this.getMessages(work_id).subscribe(
            data => {
                const messages = data;
                if (work_id && messages) {
                    let list;
                    if (latest !== undefined && oldest !== undefined) {
                        if (((messages.length) + (oldest * 10)) < 0) {
                            list = (messages).filter((x, i, a) => i < 10);
                            this.isoldestSource.next(1);
                            this.islatestource.next(0);
                        } else if (((messages.length) + (latest * 10)) > messages.length) {
                            list = (messages).filter((x, i, a) => i > messages.length - 10);
                            this.isoldestSource.next(0);
                            this.islatestource.next(1);
                        } else {
                            if (latest <= 0) {
                                list = (messages).filter((x, i, a) => i <= ((messages.length) + (latest * 10)) &&
                                    i > ((messages.length) + (oldest * 10)));
                            }
                        }
                    } else {
                        if (messages.length < 10) {
                            this.isoldestSource.next(1);
                            this.islatestource.next(1);

                            if (messages.length !== 0) {
                                list = messages;
                            } else {
                                list = [];
                            }
                        } else {
                            list = (messages).filter((x, i, a) => i > messages.length - 10);
                            this.islatestource.next(1);
                            this.isoldestSource.next(0);
                        }
                    }
                    callback(list);
                }
            },
            err => { console.log(err); }
        );
    }

    // set Home page
    public setIsHome() {
        this.isHomeSource.next(0);
    }

    public setLatest(x: number) {
        this.latestource.next(x);
    }

    public setOldest(y: number) {
        this.oldestSource.next(y);
    }

    public setIsLatest() {
        this.islatestource.next(1);
    }

    public setIsOldest() {
        this.isoldestSource.next(1);
    }

    // set last message saved time
    setLastMsgDate() {
        this.lastmsgSource.next(new Date().toLocaleString());
    }

    // sort messages by date
    public sortByWorkCreationDate(array: Message[]): Message[] {
        if (array.length > 1) {
            return array.sort(function (a, b) {
                return (b.creationDateTime < a.creationDateTime) ? 1 : (b.creationDateTime > a.creationDateTime) ? -1 : 0;
            });
        }
        return array;
    }

    // get unique date list
    private getUniqueCreationDateList(array: Message[]): string[] {
        if (array) {
            const dateList = array.map(data => (new Date(data.creationDateTime).toLocaleDateString()));
            const uniqueDateList = dateList.filter((x, i, a) => x && a.indexOf(x) === i);
            return uniqueDateList;
        } else {
            return [];
        }
    }

    // get un seen messages
    public getUnSeenMessages(user_type: string, work: Work): Message[] {
        if (this._messageList) {
            const data = this._messageList;
            switch (user_type) {
                case 'clinic':
                    return (data.filter((x, i, a) => work._id != null && x.workId === work['_id']
                        && x.owner === 'lab' && x.readDateTime === ''));
                case 'lab':
                    return (data.filter((x, i, a) => work._id != null && x.workId === work['_id']
                        && x.owner === 'clinic' && x.readDateTime === ''));
                default:
                    console.log('no user provided!');
                    return [];
            }
        }
        return [];
    }

    // convert datetime
    public getConvertedTime(dateTime: string) {
        const offset = new Date().getTimezoneOffset() * (-1);
        const serverDate = new Date(dateTime);
        const nd = new Date(serverDate.getTime() + (offset / 60));
        return nd.toISOString();
    }

    public getConvertedDate(dateTime: string) {
        const offset = new Date().getTimezoneOffset() * (-1);
        const serverDate = new Date(dateTime);
        const nd = new Date(serverDate.getTime() + (offset / 60)).toLocaleDateString();

        return this.datepipe.transform(nd, 'yyyy-MM-dd');
    }

    /* localization */
    public setLabels() {
        this.translate.get('resource.areYousure').subscribe((translation: string) => {
            this.translateLabels['rusure'] = translation;
        });
        this.translate.get('resource.cantRevert').subscribe((translation: string) => {
            this.translateLabels['cantRevert'] = translation;
        });
        this.translate.get('resource.cancel').subscribe((translation: string) => {
            this.translateLabels['cancel'] = translation;
        });
        this.translate.get('resource.yesDelete').subscribe((translation: string) => {
            this.translateLabels['yesDelete'] = translation;
        });
        this.translate.get('resource.messageDeleted').subscribe((translation: string) => {
            this.translateLabels['messageDeleted'] = translation;
        });
        this.translate.get('resource.attachmentDeleted').subscribe((translation: string) => {
            this.translateLabels['attachmentDeleted'] = translation;
        });
        this.translate.get('resource.cancelled').subscribe((translation: string) => {
            this.translateLabels['cancelled'] = translation;
        });
        this.translate.get('resource.uploadSuccess').subscribe((translation: string) => {
            this.translateLabels['uploadSuccess'] = translation;
        });
        this.translate.get('resource.yesChange').subscribe((translation: string) => {
            this.translateLabels['yesChange'] = translation;
        });
        this.translate.get('resource.changedSuccess').subscribe((translation: string) => {
            this.translateLabels['changedSuccess'] = translation;
        });
        this.translate.get('resource.alreadyAdded').subscribe((translation: string) => {
            this.translateLabels['alreadyAdded'] = translation;
        });
        this.translate.get('resource.workItemLocationChanged').subscribe((translation: string) => {
            this.translateLabels['workItemLocationChanged'] = translation;
        });
        this.translate.get('resource.lab').subscribe((translation: string) => {
            this.translateLabels['lab'] = translation;
        });
        this.translate.get('resource.clinic').subscribe((translation: string) => {
            this.translateLabels['clinic'] = translation;
        });
        this.translate.get('resource.today').subscribe((translation: string) => {
            this.translateLabels['today'] = translation;
        });
        this.translate.get('resource.workStatusChanged').subscribe((translation: string) => {
            this.translateLabels['workStatusChanged'] = translation;
        });
        this.translate.get('resource.active').subscribe((translation: string) => {
            this.translateLabels['active'] = translation;
        });
        this.translate.get('resource.pending').subscribe((translation: string) => {
            this.translateLabels['pending'] = translation;
        });
        this.translate.get('resource.closed').subscribe((translation: string) => {
            this.translateLabels['closed'] = translation;
        });
        this.translate.get('resource.profileUpdated').subscribe((translation: string) => {
            this.translateLabels['profileUpdated'] = translation;
        });

        this.tanslateLabelSource.next(this.translateLabels);
    }

    // get all labs
    public getLabsByCenterGuid(centerGuid: string) {
        return this.http.get(`${this.url}${LABS.GET_LABS_BY_CENTER_GUID}${centerGuid}`)
            .pipe(catchError(err => of(err)));
    }

    saveWork(language: string, work: Work): any {
        return this.http.post(ApiConnection.BASE_URL + ApiConnection.API_VERSION + WORKS.SAVE_WORK + language, work);
    }

    updateWork(work: Work): any {
        return this.http.put(ApiConnection.BASE_URL + ApiConnection.API_VERSION + WORKS.UPDATE_WORK + `${work._id}`, work);
    }

    deleteWork(work: Work): any {
        return this.http.delete(ApiConnection.BASE_URL + ApiConnection.API_VERSION + WORKS.DELETE_WORK + `${work._id}`);
    }
}
