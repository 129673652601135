import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const I18N_VALUES = {
    'es': {
        weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        months: ['Enero', 'Feb', 'Marzo', 'Abr', 'Mayo', 'Jun', 'Jul', 'Agosto', 'Sept', 'Oct', 'Nov', 'Dic']
    },
    'en': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    'pt': {
        weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
        months: ['Jan.', 'Fev.', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Set.', 'Out.', 'Nov.', 'Dez.']
    }
};

@Injectable()
export class I18n {
    language = 'en';
}

@Injectable()
export class CustomDatepickerI18nService extends NgbDatepickerI18n {

    constructor(private _translateService: TranslateService) {
        super();
    }

    getWeekdayLabel(weekday: number): string {
        return I18N_VALUES[this._translateService.currentLang].weekdays[weekday - 1];
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._translateService.currentLang].weekdays[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this._translateService.currentLang].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
