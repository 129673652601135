<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'authenticate.selectACenter' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <label class="container" *ngFor="let assoc of associationList; let i = index;">
        {{ assoc.clinicName }}
        <input type="radio" [checked]="i == 0" formControlName="centerGuid" [value]="assoc.clinicGuid">
        <span class="checkmark"></span>
      </label>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="proceedWithCenter()">
    {{ 'authenticate.proceed' | translate }}</button>
</div>